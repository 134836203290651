<template>
    <div>
      <h1>Game Categories</h1>
      <div class="button-container">
        <Button label="Add Category" @click="openModal()" />
      </div>
      <ul class="category-list">
        <li v-for="category in categories" :key="category.id" class="category-item">
          <span class="category-name">{{ category.category }}</span>
          <div class="button-group">
            <Button label="Edit" @click="openModal(category)" class="p-button-secondary" />
            <Button label="Delete" class="p-button-danger ml-3" @click="deleteCategory(category.id)" />
          </div>
        </li>
      </ul>
      <Dialog v-model:visible="showModal" :header="isEdit ? 'Edit Category' : 'Add Category'" modal closable="false">
        <div style="padding: 20px; background: #fff; box-shadow: 0 0 10px rgba(0,0,0,0.1);">
          <input type="text" v-model="currentCategory.category" placeholder="Category Name" style="width: 100%; margin-bottom: 10px; padding: 8px; border: 1px solid #ccc;">
        </div>
        <template #footer>
          <Button label="Save" class="p-button-text" @click="saveCategory" />
          <Button label="Cancel" class="p-button-text" @click="closeModal" />
        </template>
      </Dialog>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  
  export default {
    components: {
      Dialog,
      Button
    },
    data() {
      return {
        categories: [],
        showModal: false,
        currentCategory: {},
        isEdit: false
      };
    },
    methods: {
      fetchCategories() {
        axios.get(`${process.env.VUE_APP_API_URL}/gamecategories`).then(response => {
          this.categories = response.data;
        });
      },
      openModal(category = null) {
        this.currentCategory = category ? {...category} : { category: '' };
        this.isEdit = !!category;
        this.showModal = true;
      },
      saveCategory() {
        const url = this.currentCategory.id ? `${process.env.VUE_APP_API_URL}/gamecategories/${this.currentCategory.id}` : `${process.env.VUE_APP_API_URL}/gamecategories`;
        const method = this.currentCategory.id ? 'put' : 'post';
        axios[method](url, { category: this.currentCategory.category }).then(() => {
          this.showModal = false;
          this.fetchCategories();
        }).catch(error => {
          console.error("There was an error saving the category:", error);
        });
      },
      closeModal() {
        this.showModal = false;
      },
      deleteCategory(id) {
        axios.delete(`${process.env.VUE_APP_API_URL}/gamecategories/${id}`).then(() => {
          this.fetchCategories();
        });
      }
    },
    mounted() {
      this.fetchCategories();
    }
  }
  </script>
  
  <style scoped>
  .button-container {
    text-align: left;
    margin-bottom: 10px;
  }
  
  .category-list {
    list-style-type: none; /* Removes bullet points */
    padding: 0;
  }
  
  .category-item {
    display: flex;
    align-items: center; /* Vertical alignment */
    margin-bottom: 5px;
  }
  
  .category-name {
    flex: 1; /* Takes up the remaining space */
  }
  
  .button-group {
    margin-left: 10px; /* Space between name and buttons */
  }
  </style>
  