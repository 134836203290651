<template>
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
  <div class="certificate-container">
    <canvas ref="canvas" class="certificate" />
  </div>

  <button class="download-button" @click="downloadCertificate">Download Certificate</button>
</div>
</div>
</div>
</section>
</template>

<script>
export default {
  props: ['userFullname'],
  data() {
    return {
      studentName: '',
      certificateDate: 'May 20, 2024',
      //currentDate: new Date().toLocaleDateString(),
      certificateUrl: require('../../public/Cert.jpg')
    };
  },
  computed: {
    currentDate() {
      const now = new Date();
      const day = now.getDate();
      const month = now.toLocaleString("default", { month: "long" });
      const year = now.getFullYear();
      const suffix = day === 1 || day === 21 || day === 31 ? "st" : day === 2 || day === 22 ? "nd" : day === 3 || day === 23 ? "rd" : "th";

      return `${day}${suffix} ${month}, ${year}`;
    }
  },
  mounted() {
  const userFullname = this.$route.query.userFullname;
  this.studentName = userFullname || '';
  this.updateCertificate();
},
  methods: {
    updateCertificate() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.src = this.certificateUrl;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0);

        ctx.font = "50px Arial";
        ctx.fillStyle = "rgb(251,204,4)";
        //ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const textHeight = parseInt(ctx.font.split("px")[0]);

        ctx.fillText(
          this.studentName,
          canvas.width / 2,
          canvas.height / 2 - textHeight / 2 + textHeight / 4
        );

        ctx.font = "35px Arial"; // set font size for currentDate
        const dateHeight = parseInt(ctx.font.split("px")[0]);

        ctx.fillText(
          this.currentDate,
          canvas.width / 2,
          canvas.height - dateHeight / 2 - 70 // 20 is the gap between the date and the bottom of the image
        );
      };
    },
    downloadCertificate() {
      const canvas = this.$refs.canvas;
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = "certificate.png";
      link.click();
    }
  }
};
</script>

<style>
.certificate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.certificate {
  max-width: 80%;
  max-height: 80%;
}

.download-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: rgb(251,204,4);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.download-button:hover {
  background-color: rgb(236,181,10);
}
</style>
