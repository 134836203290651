<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Profile</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Profile</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- User Details -->
      <div class="card">
        <div class="card-body">
          <div class="user-details">
            <p><strong>Name:</strong> {{ user.fullname }}</p>
            <p><strong>PUBGM ID:</strong> {{ user.pubgmid }}</p>
            <p><strong>Mobile Number:</strong> {{ user.mobilenum }}</p>
          </div>

          <h3>Reset your password</h3>
          <!-- Reset Password Form -->
          <form @submit.prevent="resetPassword" class="mt-4">
            <div class="input-group">
              <input
                type="password"
                class="form-control"
                placeholder="New Password"
                v-model="newPassword"
              />
              <div class="input-group-append ml-3">
                <button type="submit" class="btn btn-primary">
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification"; // Import useToast from vue-toastification

export default {
  data() {
    return {
      user: {
        fullname: "",
        pubgmid: "",
        mobilenum: "",
      },
      newPassword: "",
    };
  },
  created() {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      const userData = JSON.parse(atob(userToken.split(".")[1])).data;
      this.user = userData;
    } else {
      this.$router.replace("/login");
    }
  },
  methods: {
    resetPassword() {
      const toast = useToast(); // Initialize toastification
      const userToken = localStorage.getItem("userToken");

      axios
        .post(
          "https://pubgmwow.pk/submitForm1.php",
          {
            action: "update_password",
            userId: this.user.userId,
            newPassword: this.newPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.message === "Password update successful") {
            toast.success("Password has been reset successfully.", {
              timeout: 5000,
            });
            this.newPassword = "";
          } else {
            toast.error("Failed to reset password.", {
              timeout: 5000,
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("An error occurred while resetting password.", {
            timeout: 5000,
          });
        });
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
.profile-box {
  margin: 2rem auto;
}
</style>
