<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <div class="brand-container d-flex justify-content-center align-items-center flex-column">
     
      <a href="/" class="brand-link text-center">
<!--         <img src="PUBGM x WOW.png" alt="PUBGM x WOW" class="brand-image elevation-3 mb-2" style="opacity: .8; max-width: 100%;">
 -->        <span class="brand-text font-weight-light">Student Portal</span>
      </a>
     

    </div>
    <!-- Sidebar -->
    <div class="sidebar sidebar-container">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="avatar3.png" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ userFullname }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link btnBG">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
         
          <li class="nav-item">
            <router-link to="/courses" class="nav-link btnBG">
              <i class="nav-icon fas fa-users"></i>
              <p>Course Videos</p>
            </router-link>
          </li>
        <li class="nav-item">
            <router-link to="/benefits" class="nav-link btnBG">
              <i class="nav-icon fas fa-gift"></i>
              <p>Course Rewards</p>
            </router-link>
          </li> 
          <!-- Other sidebar items -->
        </ul>
      </nav>
      <!-- <button class="close-btn fas fa-times" @click="closeSidebar"></button> -->
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  
  </aside>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SidebarCom',
  computed: {
    ...mapState({
      userFullname: state => state.user.fullname
    })
  },
  methods: {
    closeSidebar() {
      // Toggle sidebar visibility. Adjust this based on your actual state management
      this.$emit('toggle-sidebar');  // If parent handles the state or directly toggle a local state here
    }
  }
}
</script>

<style scoped>
.btnBG {
  background-color: rgb(251,204,4) !important;
  color: black !important;
}

.brand-container {
height: 100px; /* Adjust as needed to give space for centering */
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-image {
  max-height: 60px; /* Adjust size as needed */
}

.brand-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-btn {
  display: none; /* Hide by default */
  font-size: 24px;
  color: white;
  background: none;
  border: none;
}

.sidebar-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Add this to make the pseudo-element position properly */
}

.sidebar-container::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/sidebar.jpg'); /* Adjust the path if needed */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
  opacity: 0.5; /* Adjust the opacity value as desired (e.g., 0.5 for 50% opacity) */
}

@media (max-width: 768px) {
  .close-btn {
    display: block; /* Only display on small screens */
  }
}

</style>
