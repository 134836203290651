<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Course Rewards</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Benefits</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <hr style="color: silver;" />
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <p style="color: white">
              <b>On successfully completing the course & quiz, you will be eligible for the following rewards</b>
            </p>
          </div>
          <br />
          <div class="row">
            <div class="roulette-container spinner" :class="{'disabled-blur': courseProgress < 100}">
              <Roulette ref="wheel" :items="items" @wheel-end="onSpinEnd"
                centered-indicator
                display-shadow
                display-border
                base-display
                base-display-indicator
                base-background="#dedede"
                base-display-shadow
                easing="bounce"
              >
                <template #baseContent>
                  <div
                    v-if="reward === null"
                    :class="{'disabled-button': isSpinButtonDisabled}"
                    class="base-content"
                    @click="handleSpinClick"
                  >
                    Spin to Win
                  </div>
                </template>
              </Roulette>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" :class="{'disabled-blur':  showCertificate == false}">
      <div class="card">
        <div class="card-body">
          <div class="certificate-container">
            <canvas  :class="{'disabled-blur': showCertificate == false}" ref="canvas" class="certificate"></canvas>
          </div>
          <button v-if=" showCertificate != false" class="download-button" @click="downloadCertificate">Download Certificate</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Roulette } from "vue3-roulette";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "App",
  components: {
    Roulette,
  },
  data() {
    return {
      spinButtonClicked: false,
      prizes: [
        { name: 'Daraz Gift Card', probability: 40 },
        { name: 'Shirt', probability: 6 },
        { name: 'Cap', probability: 6 },
        { name: 'Hard Luck', probability: 48 }
      ],
      emits: ['wheel-end'],
      studentName: "",
      CertId: "",
      certificateDate: "May 20, 2024",
      certificateUrl: require("../../public/Cert.jpg"),
      showCertificate: false,
      selectedItem: null,
      reward: null,
      userProgress: [],
      quizResults: [],
      items: [
        {
          id: 1,
          name: "Hard Luck",
          htmlContent: '<div class="item-content">Hard Luck</div>',
          textColor: "#fff",
          background: "linear-gradient(135deg, rgba(0,245,255,1) 0%, rgba(0,179,255,1) 100%)",
        }, 
        {
          id: 2,
          name: "Hard Luck",
          htmlContent: '<div class="item-content">Hard Luck</div>',
          textColor: "#fff",
          background: "linear-gradient(135deg, rgba(255,225,0,1) 0%, rgba(255,182,0,1) 100%)",
        },
        {
          id: 3,
          name: "Daraz Gift Card",
          htmlContent: '<img src="Cards.png" style="width: 30%; height: 30%;" alt="Shirt" />',
          textColor: "#fff",
          background: "linear-gradient(135deg, rgba(1,0,81,1) 0%, rgba(0,0,64,1) 100%)",
        },
        {
          id: 4,
          name: "Hard Luck",
          htmlContent: '<div class="item-content">Hard Luck</div>',
          textColor: "#fff",
          background: "linear-gradient(135deg, rgba(0,245,255,1) 0%, rgba(0,179,255,1) 100%)",
        },
       /*  {
          id: 5,
          name: "Cap",
          htmlContent: '<img src="Cap.png" style="width: 20%; height: 20%" alt="Shirt" />',
          textColor: "#fff",
          background: "linear-gradient(135deg, rgba(255,225,0,1) 0%, rgba(255,182,0,1) 100%)",
        },
        {
          id: 6,
          name: "Hard Luck",
          htmlContent: '<div class="item-content">Hard Luck</div>',
          textColor: "#fff",
          background: "linear-gradient(135deg, rgba(1,0,81,1) 0%, rgba(0,0,64,1) 100%)",
        }, */
      ],
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
      userFullname: (state) => state.user.fullname,
      userPubgmid: (state) => state.user.pubgmid,
    }),

    courseProgress() {
      if (!this.userProgress) {
        return 0;
      }
      this.getUserReward();
      const totalVideos = 4; // Replace this with the actual number of videos in the course
      const completedVideos = this.userProgress.filter(
        (progress) => progress.playback_time >= 95
      ).length;
      return Math.round((completedVideos / totalVideos) * 100);
    },
    currentDate() {
      const now = new Date();
      const day = now.getDate();
      const month = now.toLocaleString("default", { month: "long" });
      const year = now.getFullYear();
      const suffix =
        day === 1 || day === 21 || day === 31
          ? "st"
          : day === 2 || day === 22
          ? "nd"
          : day === 3 || day === 23
          ? "rd"
          : "th";

      return `${day}${suffix} ${month}, ${year}`;
    },
  },
  methods: {
    getRandomNumber() {
      return Math.random();
    },
    handleSpinClick() {
    if (this.courseProgress >= 100 && this.reward === null && !this.spinButtonClicked) {
      this.spinButtonClicked = true; // Disable button immediately
      this.spin();
    }
  },
  spin() {
    const randomNumber = this.getRandomNumber();
    let cumulativeProbability = 0;
    let winningPrize = null;

    for (const prize of this.prizes) {
      cumulativeProbability += prize.probability / 100;
      if (randomNumber <= cumulativeProbability) {
        winningPrize = prize;
        break;
      }
    }

    if (this.reward === null) {
      this.$refs.wheel.launchWheel({
        item: winningPrize.name,
        onFinish: () => {
          this.reward = winningPrize.name;
          this.onSpinEnd(winningPrize);
        }
      });
    }
  },
  onSpinEnd(spunItem) {
    this.selectedItem = spunItem;
    if (this.selectedItem) {
      this.saveSpinResult();
    }
  
    this.showCertificate = true;
    this.$nextTick(() => {
     
      this.updateCertificate();
    });
  },
    isCourseComplete() {
      return this.courseProgress === 100 && this.quizResults.every(result => result);
    },

    async fetchUserProgress() {
      try {
        const response = await fetch("https://pubgmwow.pk/submitForm1.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "get_User_Progress",
            userId: this.userId,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
       // console.log(data);
        this.userProgress = data.data;

        // new code to populate quizResults
        this.quizResults = this.userProgress.map(progress => {
          if (progress.correct_answers && progress.total_questions) {
            return this.calculatePercentage(progress.correct_answers, progress.total_questions) > 59;
          }
          return false;
        });

      } catch (error) {
        console.error("Error fetching user progress:", error);
      }
    },
    calculatePercentage(correct, total) {
      return (correct / total) * 100;
    },
    updateCertificate() {
      const canvas = this.$refs.canvas;
      if (canvas) {
        const ctx = canvas.getContext("2d");
        const image = new Image();
        image.src = this.certificateUrl;

        this.CertId = "1000" + this.userId;

        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;

          ctx.drawImage(image, 0, 0);

          ctx.font = "100px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          const textHeight = parseInt(ctx.font.split("px")[0]);

          ctx.fillText(
            this.studentName,
            canvas.width / 2,
            canvas.height / 2 - textHeight / 2 
          );

          ctx.font = "50px Arial";
          const uidHeight = parseInt(ctx.font.split("px")[0]);

          ctx.fillText(
            this.userPubgmid,
            canvas.width / 2 + 130,
            canvas.height / 2 - uidHeight + 143
          );

          ctx.font = "35px Arial";
          const dateHeight = parseInt(ctx.font.split("px")[0]);

          ctx.fillText(
            this.currentDate,
            (canvas.width / 2) - 50, // Adjust this value to move the text left or right
            canvas.height - dateHeight / 2 - 198
          );

          ctx.font = "35px Arial";
          const certHeight = parseInt(ctx.font.split("px")[0]);

          ctx.fillText(
            this.CertId,
            (canvas.width / 2) + 70, // Adjust this value to move the text left or right
            canvas.height - certHeight / 2 - 50
          );
        };
      }
    },
    async getUserReward() {
      try {
        const response = await axios.post("https://pubgmwow.pk/submitForm1.php", {
          action: "get_User_Reward",
          userId: this.userId,
        });

        if (response.data !== null && response.data !== '') {
          const reward = response.data;
          if (reward !== null) {
            this.reward = reward;
            this.showCertificate = true;
            this.$nextTick(() => {
              this.updateCertificate();
            });
          }
        } else {
         // console.error("Null response from server");
        }
      } catch (error) {
        console.error(error);
      }
    },
    downloadCertificate() {
      const canvas = this.$refs.canvas;
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = "certificate.png";
      link.click();
    },
    async saveSpinResult() {
      if (!this.selectedItem) {
        console.error("No selected item to save");
        return;
      }

      const data = {
        action: "save_spin_result",
        userId: this.userId,
        reward: this.selectedItem.name,
      };

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post("https://pubgmwow.pk/submitForm1.php", data);
       // console.log(response.data);

        // Update the progress of probabilities
        const prize = this.prizes.find(prize => prize.name === this.selectedItem.name);

        const currentProgress = await this.getProgress(prize.name);
        const newProgress = {
          num_students_won: currentProgress.num_students_won + 1,
          total_students: currentProgress.total_students + 1
        };
        await this.updateProgress(prize.name, newProgress);
      } catch (error) {
        console.error(error);
      }
    },
 
    async updateProgress(prizeName, progress) {
      try {
      // alert(prizeName);
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post("https://pubgmwow.pk/submitForm1.php", {
          action: "update_progress",
          prize_name: prizeName,
          num_students_won: progress.num_students_won,
          total_students: progress.total_students
        });
      //  console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async getProgress() {
      try {
        const response = await axios.post("https://pubgmwow.pk/submitForm1.php", {
          action: "get_prize_progress",
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return { num_students_won: 0, total_students: 0 };
      }
    },
    async checkPrizeProbability() {
  const totalStudents = await this.getTotalStudents();

  const progress = await this.getProgress(); // Fetch the progress data for all prizes

  for (const prize of this.prizes) {
    const studentsWon = progress.find(p => p.prize_name === prize.name).num_students_won; // Find the progress data for the current prize

   // console.log(studentsWon);

    // Check if the number of students who won the prize is equal to or greater than the prize's probability
    if (studentsWon / totalStudents >= prize.probability / 100) {
   //   console.log(`Prize ${prize.name} has reached its probability and will be removed`); // Check if the condition is met
      if (prize.name === 'Cap' || prize.name === 'Shirt' || prize.name === 'Daraz Gift Card') {
        // Remove the prize from the items array
        this.items = this.items.filter(item => item.name !== prize.name);
      }
    }
  }
}


,

async getTotalStudents() {
  try {
    const response = await axios.post('https://pubgmwow.pk/submitForm1.php',{
       action: "get_totalStudents_Spun",
    });
    return response.data.total_students;
  } catch (error) {
    console.error(error);
    return 0;
  }
}

  },

  async created() {
    this.studentName = this.userFullname || "";
    await this.checkPrizeProbability(); // Call the new method here

    await this.fetchUserProgress();
    this.getUserReward();
    this.updateCertificate();
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-body {
  background-image: url("../../public/BG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
}
.certificate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.certificate {
  display: block;
  max-width: 60%;
  max-height: 40%;
}
.profile-box {
  margin: 2rem auto;
}
.spinner {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.download-button {
  margin-top: 20px;
  background-color: rgb(236, 181, 10);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}
.item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
}
.item-image {
  max-width: 70%;
  max-height: 70%;
}
.roulette-container {
  border: 4px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.base-content {
  border: 4px solid rgb(236,181,10); /* Set the border color */
  padding: 10px;
  border-radius: 50%; /* Make it round */
  background-color: black; /* Ensure the background is black */
  color: white; /* Text color */
  text-align: center; /* Center the text */
  width: 150px; /* Set a fixed width */
  height: 150px; /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px; /* Adjust the font size */
  cursor: pointer;
}

.disabled-blur {
  filter: blur(5px);
  pointer-events: none;
  opacity: 0.7;
}
</style>
