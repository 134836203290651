import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  state: {
    user: {
      fullname: '',
      token: '',
      userId: '',
      pubgmid: ''
    }
  },
  mutations: {
    setUser(state, userData) {
      state.user.fullname = userData.fullname;
      state.user.token = userData.token;
      state.user.userId = userData.userId;
      state.user.pubgmid = userData.pubgmid;
    },
    clearUser(state) {
      state.user.fullname = '';
      state.user.token = '';
      state.user.userId = '';
      state.user.pubgmid = '';
    }
  },
  actions: {
    loginUser({ commit }, userData) {
      commit('setUser', userData);
    },
    logoutUser({ commit }) {
      commit('clearUser');
    }
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: 'myPUBGMOBILEWOWStore', // Specify a key to use in localStorage
      paths: ['user'], // Specify which parts of the state should be persisted
    })
  ]
});

export default store;
