<template>
 <nav class="main-header navbar navbar-expand navbar-dark navbar-light">
  <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link btnBG" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
       
      </ul>
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <img src="PUBGM x WOW.png" alt="PUBGM x WOW" style="width: 100px; height: 50px;">

        </li>
       
      </ul>
      <ul class="navbar-nav ml-auto">
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
       <i class="fas fa-th-large" style="color:rgb(251,204,4); font-size: 26px"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="#" @click="viewProfile">Profile</a>
        <a class="dropdown-item" href="#" @click="logout">Logout</a>
      </div>
    </li>
  </ul>

  </nav>
</template>


  <script>
  export default {
    name: 'NavbarCom',
    methods: {
      logout() {
        // Clear user authentication token
        localStorage.removeItem('userToken');
        // Redirect user to login page
        this.$router.push('/');
      },
      viewProfile() {
        event.preventDefault(); 
        this.$router.push('/profile');
      }
    }
  }
  </script>
  
  <style scoped>
  .btnBG {
  background-color: rgb(251,204,4) !important;
  color: black !important;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-nav.mx-auto {
  flex: 1;
  display: flex;
  justify-content: center;
}
  </style>
  