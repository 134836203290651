// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import PrimeVue from 'primevue/config'; // Import PrimeVue config
import 'primevue/resources/themes/saga-blue/theme.css'; // Theme CSS
import 'primevue/resources/primevue.min.css'; // PrimeVue CSS
import 'primeicons/primeicons.css'; // PrimeIcons CSS

const app = createApp(App);

app.use(store); // Vuex store
app.use(router); // Vue Router
app.use(PrimeVue); // PrimeVue
app.use(Toast); // Toast notifications

app.mount('#app');
