<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h5 class="m-0">Welcome: {{ userFullname }} to <br />
          PUBG MOBILE GameCraft</h5>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">{{ breadcrumbText }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body" style="padding:0 !important">
          <div v-for="(video, index) in videos" :key="index" v-show="currentTab === index">
            <div v-if="showQuiz && currentQuestion">
              <div class="quiz-question" :style="quizStyle">
                <p>{{ currentQuestion.text }}</p>
                <ul>
                  <li v-for="(option, optionIndex) in currentQuestion.options" :key="optionIndex">
                    <input type="radio" :id="'q' + currentQuestionIndex + optionIndex" :name="'qs' + currentQuestionIndex" :value="optionIndex" v-model="selectedOptions[currentQuestionIndex]" />
                    <label :for="'q' + currentQuestionIndex + optionIndex">{{ option }}</label>
                  </li>
                </ul>
                <button class="btn btnBG" @click="submitQuestion" :disabled="!isOptionSelected">Submit Answer</button>
              </div>
            </div>

            <div class="video-container" v-if="!showQuiz && !quizSummaryVisible" style="width: 100%;">
              <video :ref="'videoPlayer' + index" class="video-player" controls playsinline @play="handleVideoPlay(index)" @timeupdate="event => handleVideoProgress(event, index)" @ended="handleVideoEnded(index)">
                <source :src="video.source" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <br />
            <hr />
            <div class="game-development-info ml-2 mr-2" v-if="!quizSummaryVisible">
              <h6>Episode 1: Foundations of Game Design
              </h6>
              <p style="font-size: 12px;">
                <b>Description:</b> Discover the fundamental principles of game design, including gameplay mechanics, player engagement, and balancing challenge and reward. Learn how different game types and core elements create fun and engaging experiences.
              </p>
            </div>

            <div v-if="quizSummaryVisible" class="quiz-summary ml-2 mr-2">
              <h2>Quiz Summary</h2>
              <div v-for="(question, index) in questions" :key="index" class="question-summary">
                <p>{{ question.text }}</p>
                <ul>
                  <li v-for="(option, optionIndex) in question.options" :key="optionIndex">
                    <span :class="{ 
                      'correct-answer': optionIndex === correctAnswers[currentTab][index], 
                      'wrong-answer': optionIndex === selectedOptions[index] && optionIndex !== correctAnswers[currentTab][index], 
                      'neutral-answer': optionIndex !== correctAnswers[currentTab][index] && optionIndex !== selectedOptions[index] 
                    }">{{ option }}</span>
                  </li>
                </ul>
              </div>
              <button @click="hideQuizSummary" class="btn btnBG">Try Again</button>
              <button @click="sendQuizResultToServer" style="display:none" class="btn btnBG ml-3">Next Course</button>
              <button v-if="showNextButton" @click="goToNextCourse" class="btn btnNext ml-3">Next Episode</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="popupVisible" class="popup">
      <div class="popup-content">
        <p>{{ popupMessage }}</p>
        <button @click="hidePopup">Close</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      quizIds: [1],
      popupVisible: false,
      popupMessage: '',
      videos: [
        { id: 1, name: 'Episode 1', source: 'https://d2dc70bwwfynif.cloudfront.net/Episode-1.mp4' },
      ],
      questions: [
        {
          text: 'What is the primary focus of the game designers in a game development team?',
          options: [
            'A) Coding the game',
            'B) Creating engaging gameplay mechanics and narratives',
            'C) Designing the game\'s sound and music',
            'D) Marketing and promoting the game'
          ],
          threshold: 175, // 10 seconds
          correctAnswer: 1 // Index of the correct answer
        },
        {
          text: 'What ensures that players of all skill levels have a fair and challenging experience in game design?',
          options: [
            'A) Immersion and storytelling',
            'B) Advancement and rewards',
            'C) Maintaining balance and difficulty',
            'D) Providing feedback'
          ],
          threshold: 255, // 4 minutes 15 seconds
          correctAnswer: 2
        },
        {
          text: 'What does the MDA framework in game design stand for?',
          options: [
            'A) Mechanics, Dynamics, Aesthetics',
            'B) Mechanics, Design, Animation',
            'C) Methods, Development, Application',
            'D) Models, Details, Actions'
          ],
          threshold: 361, // 6 minutes 1 second
          correctAnswer: 0
        }
      ],
      currentQuestionIndex: 0,
      selectedOptions: {},
      currentTab: 0,
      showQuiz: false,
      currentVideoIndex: 0,
      enabledTabs: [0],
      savedCurrentTime: 0,
      videoToResume: null,
      quizShownForVideo: {},
      quizCompleted: false,
      quizSummaryVisible: false,
      showNextButton: false, // New property to control button visibility
      correctAnswers: [
        [1, 2, 0]
      ],
      quizResults: {}
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId,
      userFullname: state => state.user.fullname
    }),
    quizStyle() {
      const video = this.getVideoElement();
      if (video) {
        return {
          width: `${video.clientWidth}px`,
          height: `${video.clientHeight}px`
        };
      }
      return {};
    },
    currentQuestion() {
      return this.questions[this.currentQuestionIndex] || null;
    },
    isOptionSelected() {
      return this.selectedOptions[this.currentQuestionIndex] !== undefined;
    },
    currentQuizId() {
      return this.quizIds[this.currentTab];
    },
    breadcrumbText() {
      return `${this.currentTab + 1}/4 Videos`;
    },
    incorrectAnswersCount() {
    return this.quizResults.filter(result => !result.is_correct).length;
  }
  },
  created() {
    this.quizShownForVideo[this.currentTab] = [];
    this.checkVideoProgress();
    this.getQuizResults(this.userId); // Fetch quiz results when the component is created

  },
  methods: {
    goToNextCourse() {
      this.$emit('switch-tab', 1);
    },
    showPopup(index) {
      this.popupMessage = `Tab ${index + 1} is disabled. Please complete the previous videos to unlock this tab.`;
      this.popupVisible = true;
    },
    hidePopup() {
      this.popupVisible = false;
    },
    async checkVideoProgress() {
      try {
        const response = await fetch('https://pubgmwow.pk/submitForm1.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'get_video_progress',
            userId: this.userId
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.message !== 'Video progress retrieved successfully') {
          console.error(`Failed to retrieve video progress: ${data.message}`);
          return;
        }

        this.videoProgress = data.data;
        this.enabledTabs = [0];

        for (let i = 0; i < this.videos.length; i++) {
          const videoId = String(this.videos[i].id);
          const progress = this.videoProgress.find(progress => String(progress.video_id) === videoId);

          if (progress && progress.playback_time >= 100) {
            if (!this.enabledTabs.includes(i)) {
              this.enabledTabs.push(i);
            }
            if (i < this.videos.length - 1) {
              this.enabledTabs.push(i + 1);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching video progress:', error);
      }
    },

    async getQuizResults(userId) {
  try {
    const response = await fetch('https://pubgmwow.pk/submitForm1.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        action: 'get_quiz_results5',
        userId: userId,
        quizId: this.currentQuizId
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data)
    if (data.message !== 'Quiz results retrieved successfully') {
      console.error(`Failed to retrieve quiz results: ${data.message}`);
      return;
    }

    this.quizResults = data.data.map(result => ({
      question_index: parseInt(result.question_index, 10),
      is_correct: result.is_correct === '1'
    })); // Store quiz results with parsed integers and boolean for correctness
  } catch (error) {
    console.error('Error fetching quiz results:', error);
  }
}
,
handleVideoPlay(index) {
  const video = this.$refs['videoPlayer' + index][0];
  if (video.currentTime === 0) {
    this.quizShownForVideo[index] = [];
  }

  this.videos.forEach((video, idx) => {
    if (idx !== index) {
      const otherVideo = this.$refs['videoPlayer' + idx] ? this.$refs['videoPlayer' + idx][0] : null;
      if (otherVideo && !otherVideo.paused) {
        otherVideo.pause();
      }
    }
  });
},
shouldShowQuiz(currentTime) {
  const question = this.questions[this.currentQuestionIndex];
  if (!question) return false;

  const threshold = question.threshold;
  const shownThresholds = this.quizShownForVideo[this.currentVideoIndex] || [];

  return currentTime >= threshold && !shownThresholds.includes(threshold);
},

async handleVideoProgress(event, index) {
  const video = event.target;
  const currentTime = video.currentTime;

  if (this.shouldShowQuiz(currentTime)) {
    this.showQuiz = true;
    this.currentVideoIndex = index;
    this.savedCurrentTime = video.currentTime;
    video.pause();
    this.videoToResume = video;
    if (!this.quizShownForVideo[this.currentVideoIndex]) {
      this.quizShownForVideo[this.currentVideoIndex] = [];
    }
    this.quizShownForVideo[this.currentVideoIndex].push(this.questions[this.currentQuestionIndex].threshold);
  } else if (!video.paused) {
    await this.sendProgressToAPI(video, index);
  }

  const progress = (currentTime / video.duration) * 100;
  if (Math.round(progress) === 100) {
    if (index < this.videos.length - 1 && !this.enabledTabs.includes(index + 1)) {
      this.enabledTabs.push(index + 1);
    }
  }
}
,
async sendProgressToAPI(video, index) {
  try {
    const userId = this.userId;
    const videoId = this.videos[index].id;
    const currentTime = video.currentTime;
    const duration = video.duration;

    if (duration === 0) {
      console.warn('Video duration is invalid (zero).');
      return;
    }

    let playbackTime = (currentTime / duration) * 100;

    const response = await fetch('https://pubgmwow.pk/submitForm1.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        action: 'update_video_progress',
        userId: userId,
        videoId: videoId,
        playbackTime: playbackTime
      })
    });

    const data = await response.json();

    if (!response.ok || data.message !== 'Video progress updated successfully') {
      throw new Error(`Failed to update video progress: ${data.message}`);
    } else {
      //console.log('Progress sent successfully:', data);
    }
  } catch (error) {
    //console.error('Error sending progress:', error);
  }
},


    async handleVideoEnded(index) {
  if (index === this.currentTab) {
    this.showQuiz = false;
    this.quizSummaryVisible = true;

    await this.getQuizResults(this.userId);

    const correctAnswersCount = this.quizResults.filter(result => result.is_correct).length;

    this.showNextButton = correctAnswersCount >= this.questions.length - 2;
  }
},


    submitQuestion() {
    const question = this.currentQuestion;
    if (question) {
      this.quizShownForVideo[this.currentVideoIndex] = this.quizShownForVideo[this.currentVideoIndex] || [];
      this.quizShownForVideo[this.currentVideoIndex].push(question.threshold);
    }

    const isCorrect = this.getIsCorrect(this.currentQuestionIndex, this.selectedOptions[this.currentQuestionIndex]);
    this.quizResults.push({
      question_index: this.currentQuestionIndex + 1,
      is_correct: isCorrect
    });

    if (this.currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionIndex++;
    } else {
      this.currentQuestionIndex = 0;
      this.quizCompleted = true;
    }

    this.sendQuizResultToServer();
    this.showQuiz = false;
    this.$nextTick(() => {
      if (this.videoToResume) {
        this.resumeVideo();
      }
    });
  },


    async sendQuizResultToServer() {
      try {
        if (!this.userId || !this.currentQuizId || Object.keys(this.selectedOptions).length === 0) {
          throw new Error('Missing required parameters: userId, quizId, or selectedOptions.');
        }

        const results = Object.keys(this.selectedOptions).map(index => ({
          quiz_id: this.currentQuizId,
          question_index: Number(index) + 1,
          answer: this.getAnswerLabel(this.selectedOptions[index]),
          is_correct: this.getIsCorrect(Number(index), this.selectedOptions[index]) ? 1 : 0
        }));

        const payload = {
          action: 'save_quiz_results',
          userId: this.userId,
          quizId: this.currentQuizId,
          results: results
        };

        const response = await fetch('https://pubgmwow.pk/submitForm1.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
        }

        const data = await response.json();
        console.log('Quiz result sent successfully:', data);
      } catch (error) {
        console.error('Error sending quiz result:', error.message);
      }
    },
    resumeVideo() {
      if (this.videoToResume) {
        const video = this.getVideoElement();
        if (video) {
          video.currentTime = this.savedCurrentTime;
          video.play()
            .then(() => {
              this.videoToResume = null;
            })
            .catch(error => {
              console.error('Error playing video:', error);
            });
        } else {
          console.error('Video element not found');
        }
      }
    },
    selectTab(index) {
      this.currentTab = index;
      this.showQuiz = false;
      this.videoToResume = null;
      this.quizCompleted = false;
      this.quizSummaryVisible = false;
    },
    hideQuizSummary() {
      this.quizSummaryVisible = false;
      this.checkVideoProgress();
    },
    getVideoElement() {
      const videoPlayer = this.$refs['videoPlayer' + this.currentTab];
      return videoPlayer ? videoPlayer[0] : null;
    },
    getAnswerLabel(answerIndex) {
      const question = this.questions[this.currentQuestionIndex];
      return question ? question.options[answerIndex] : null;
    },
    getIsCorrect(questionIndex, answerIndex) {
      return answerIndex === this.correctAnswers[this.currentTab][questionIndex];
    }
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

.popup-content p {
  margin: 0 0 20px 0;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.active {
  background-color: #007bff;
  color: white;
}
.btnBG {
  background-color: rgb(251,204,4) !important;
  color: black !important;
}

.btnNext {
  background-color: green !important;
  color: white !important;
}

.quiz-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.video-container {
  display: flex;
  justify-content: center;
}
.video-player {
  max-width: 100%;
  width: 100%;
}
.tab-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.tab-navigation button {
  margin: 0 5px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .tab-navigation button {
    padding: 5px 10px;
    font-size: 12px;
  }
}
.quiz-summary {
  margin-top: 20px;
}
.correct-answer {
  color: green;
}
.wrong-answer {
  color: red;
}
.neutral-answer {
  color: black;
}
.quiz-question p {
  font-size: 12px;
}

.quiz-question ul li {
  font-size: 12px;
}
</style>
