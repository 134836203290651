<template>
  <div>
  

    <!-- Main content for userId 349 -->
    <div >
      <div class="tab-content">
        <div v-if="currentTab === 0">
          <Course1View @switch-tab="selectTab" />
        </div>
        <div v-if="currentTab === 1">
          <Course2View @switch-tab="selectTab" />
        </div>
        <div v-if="currentTab === 2">
          <Course3View @switch-tab="selectTab" />
        </div>
        <div v-if="currentTab === 3">
          <Course4View />
        </div>
      </div>

      <div class="tab-navigation">
        <button
          v-for="(tab, index) in tabs"
          :key="tab.id"
          @click="selectTab(index)"
          :class="{ active: currentTab === index }"
          :disabled="!enabledTabs.includes(index)"
        >
          <span v-text="tab.title"></span>
        </button>
      </div>
    </div>

    
  </div>
</template>

<script>
import Course1View from './Course1View.vue';
import Course2View from './Course2View.vue';
import Course3View from './Course3View.vue';
import Course4View from './Course4View.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Course1View,
    Course2View,
    Course3View,
    Course4View,
  },
  data() {
    return {
      currentTab: 0,
      videoProgress: [],
      enabledTabs: [0], // Default to only enabling the first tab
      tabs: [
        { id: 1, title: 'Episode 1' },
        { id: 2, title: 'Episode 2' },
        { id: 3, title: 'Episode 3' },
        { id: 4, title: 'Episode 4' }
      ]
    };
  },
  created() {
    this.checkVideoProgress();
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId,
      userFullname: state => state.user.fullname
    })
  },
  methods: {
    selectTab(index) {
      this.currentTab = index;
    },
    async checkVideoProgress() {
      try {
        console.log(this.userId)
        const response = await fetch('https://pubgmwow.pk/submitForm1.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'get_video_progress',
            userId: this.userId
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Fetched video progress data:", data);

        if (!data.data || data.data.length === 0) {
          console.error(`Failed to retrieve video progress or data is null/empty: ${data.message}`);
          this.videoProgress = [];
          this.enabledTabs = [0]; // Ensure only the first tab is enabled
          return;
        }

        this.videoProgress = data.data;
        this.updateEnabledTabs();
      } catch (error) {
        console.error('Error fetching video progress:', error);
        this.enabledTabs = [0]; // Ensure only the first tab is enabled on error
      }
    },
    updateEnabledTabs() {
      const newEnabledTabs = [0]; // Always enable the first tab
      let previousTabCompleted = true;

      for (let i = 0; i < this.videoProgress.length; i++) {
        const progress = this.videoProgress[i];
        console.log(`Processing video_id: ${progress.video_id}, playback_time: ${progress.playback_time}`);
        if (previousTabCompleted && parseInt(progress.playback_time) >= 100) {
          // Enable the tab if the previous tab was completed and current tab's playback time is 100%
          newEnabledTabs.push(parseInt(progress.video_id));
        } else {
          // If the current tab is not completed, mark previousTabCompleted as false
          previousTabCompleted = false;
        }
      }

      // Ensure enabledTabs contains unique values and is sorted
      this.enabledTabs = [...new Set(newEnabledTabs)].sort((a, b) => a - b);

      console.log('Enabled tabs after processing:', this.enabledTabs);
    }
  }
};
</script>

<style scoped>
.tab-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-navigation button {
  margin: 0 5px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.tab-navigation button.active {
  background-color: blue;
  color: white;
}

.tab-navigation button:disabled {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .tab-navigation button {
    padding: 5px 10px;
    font-size: 12px;
  }
}
</style>
