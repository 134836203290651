<template>
  <div class="login-container">
   

    <div class="login-box">
      <div class="login-logo">        
      </div>
      <div class="card" :style="{ 'background-color': 'black'}">
        <div class="card-header text-center">
          <a href="https://pubgmwow.pk/"><img src="PUBGM x WOW.png" alt="PUBGM x WOW" style="width: 100px; height: 30px;"></a>
        </div>
        <div class="card-body login-card-body">
          <h4 class="login-box-msg"><b>Student</b> Login</h4>

          <form @submit.prevent="login">
            <div class="input-group mb-3">
              <input type="text" id="pubgmid" class="form-control" placeholder="Mobile Number" v-model="pubgmid">
              <div class="input-group-append">
                <div class="input-group-text  icon-width">
                  <span class="fas fa-mobile"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input :type="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password" v-model="password">
              <div class="input-group-append">
                <div class="input-group-text  icon-width" @click="togglePasswordVisibility">
                  <span :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="icheck-primary" style="display: none;">
                  <input type="checkbox" id="remember">
                  <label for="remember">
                    Remember Me
                  </label>
                </div>
              </div>
              <div class="col-4">
                <button type="submit" class="btn btn-primary btn-block btnBG">Sign In</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
// eslint-disable-next-line no-unused-vars
import { useStore } from 'vuex';

export default {
  name: 'LoginCom',
  data() {
    return {
      pubgmid: '',
      password: '',
      showPassword: false // Manage password visibility
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login() {
      axios.post('https://pubgmwow.pk/submitForm1.php', {
        action: 'login',
        pubgmid: this.pubgmid,
        password: this.password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.data.message === "Login successful") {
          this.$store.dispatch('loginUser', {
            fullname: response.data.user.fullname,
            token: response.data.userToken,
            userId: response.data.user.userId,
            pubgmid: response.data.user.pubgmid,
          });

          localStorage.setItem('userToken', response.data.userToken);

          this.$router.replace('/courses');
        } else {
          this.toast.error("Invalid credentials, please try again.", {
            timeout: 5000
          });
        }
      }).catch(error => {
        this.toast.error("Invalid credentials, please try again.", {
            timeout: 5000
        });
        console.error('Error:', error);
      });
    }
  },
  mounted() {
    this.toast = useToast();
  }
}
</script>


<style scoped>
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../public/login.jpg'); /* Adjust the path if needed */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}

.login-logo {
  color: white; /* Adjust text color to contrast with background image */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Optional: add text shadow for better readability */
}

.login-card-body {
  background-color: black;
  color: silver; /* Add a semi-transparent background to make text readable */
  padding: 1.5rem;
  border-radius: 8px;
}

/* Adjust input styles to ensure readability */
.input-group-text, .form-control {
  background: rgba(255, 255, 255, 0.9);
}

.icon-width {
  width: 40px; /* Set a fixed width for the icons */
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnBG {
  background-color: rgb(251,204,4) !important;
  color: black !important;
}

.input-group-text {
  cursor: pointer; /* Add cursor pointer to indicate the icon is clickable */
}
</style>

