<template>
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Report Card</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <!-- Main content -->
          <section class="content">
            <div class="container-fluid">
              <!-- Info boxes and content can go here -->
              <h5>Course videos are live now, watch the videos and complete the quiz. You will see your course progress here.</h5>
            
            </div>
            <!-- /.container-fluid -->
          </section>
          <hr />
          <section class="content">
            <div class="container-fluid">
              <!-- Video Progress Section -->

              <div class="row">
                <button class="btn btn-warning" disabled>
                  <b>Creator Access:</b>  {{
                  courseProgress > 99
                    ? "In Progress"
                    : "Pending"
                }}
                </button>
              
              </div>
              <div class="row">
                {{courseProgress > 99 ? "It takes 7 to 10 days to receive creator access" : ""}}
              </div>
              <hr />
              <br />
              <h4 @click="goToRewards">Course Progress</h4>
              <div v-for="(progress, index) in userProgress" :key="index">
                <div class="video-info">
                  <span>Episode {{ progress.video_id }}</span>
                  <!-- Find the quiz progress for the current video -->
                </div>
                <div class="progress progress-sm">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    :style="{ width: progress.playback_time + '%' }"
                    :aria-valuenow="progress.playback_time"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ progress.playback_time }}%
                  </div>
                </div>
                <template v-if="progress.correct_answers">
                  <span>Quiz Result: </span>
                  <span
                    v-bind:style="{
                      color:
                        calculatePercentage(
                          progress.correct_answers,
                          progress.total_questions
                        ) > 59
                          ? 'green'
                          : 'red',
                      fontWeight: 'bold',
                    }"
                  >
                    {{
                      calculatePercentage(
                        progress.correct_answers,
                        progress.total_questions
                      ) > 59
                        ? "Pass"
                        : "Fail"
                    }}
                  </span>
                </template>
                <br />
                <hr />
                <br />
              </div>
            </div>
            <!-- /.container-fluid -->
          </section>

          <section class="content">
            <div class="container-fluid center-container">
              <button
                id="btnCertificate"
                class="btn btn-primary"
                @click="goToCertificate"
                :disabled="courseProgress < 100"
              >
                {{
                  courseProgress > 99
                    ? "Click here to receive your certificate"
                    : `Certificate Progress: ${courseProgress}%`
                }}
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>

  <hr />

  <section v-if="reward !== null && reward !== 'Hard Luck'" class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          Reward - You Won 
        </div>
        <div class="card-body">
          <div v-if="reward === 'Cap'" style="display: flex; align-items: center; justify-content: center;">
            <img src="Cap1.png" style="width: 30%; height: 30%;" alt="Shirt" />
</div>
<div v-if="reward === 'Daraz Gift Card'" style="display: flex; align-items: center; justify-content: center;">
  <img src="Cards.png" style="width: 30%; height: 30%; object-fit: contain;" alt="Shirt" />
</div>

<div v-if="reward === 'Shirt'" style="display: flex; align-items: center; justify-content: center;">
  <img src="Shirt1.png" style="width: 50%; height: 20%;" alt="Shirt" />
</div>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>

</template>

<script>

import { mapState } from "vuex";
import axios from "axios";

export default {
  components: {

  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
      userFullname: (state) => state.user.fullname,
    }),
    courseProgress() {
      if (!this.userProgress) {
        return 0;
      }
      //this.isCourseComplete();
      const totalVideos = 4; // Replace this with the actual number of videos in the course
      const completedVideos = this.userProgress.filter(
        (progress) => progress.playback_time >= 95
      ).length;
      return Math.round((completedVideos / totalVideos) * 100);
      
    },
  },
  data() {
    return {
      userProgress: [], // Initialize user progress data
      quizResults: [],
      reward: null,
    };
  },
  mounted() {
    this.fetchUserProgress();
    this.getUserReward();
  },
  methods: {
    async getUserReward() {
      try {
        const response = await axios.post("https://pubgmwow.pk/submitForm1.php", {
          action: "get_User_Reward",
          userId: this.userId,
        });

        if (response.data !== null && response.data !== '') {
          const reward = response.data;
          if (reward !== null) {
            this.reward = reward;  
                   
          }
        } else {
          console.error("Null response from server");
        }
      } catch (error) {
        console.error(error);
      }
    },
    isCourseComplete() {
      if ( this.courseProgress === 100 && this.quizResults.every(result => result))
      {
        return true;
      }
      else
      {
        return false;
      }
    },
    calculatePercentage(correct, total) {
      return (correct / total) * 100;
    },
    goToCertificate() {
      this.$router.push({
        name: "BenefitsView",
        query: { userFullname: this.userFullname },
      });
    },
    goToRewards() {
      this.$router.push({
        name: "BenefitsView",
        props: { userFullname: this.userFullname },
      });
    },
    async fetchUserProgress() {
  try {
    const response = await fetch("https://pubgmwow.pk/submitForm1.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "get_User_Progress",
        userId: this.userId,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    this.userProgress = data.data;

    // new code to populate quizResults
    this.quizResults = this.userProgress.map(progress => {
      if (progress.correct_answers && progress.total_questions) {
        return this.calculatePercentage(progress.correct_answers, progress.total_questions) > 59;
      }
      return false;
    });

  } catch (error) {
    console.error("Error fetching user progress:", error);
  }
},
  },
};
</script>

<style scoped>
.video-progress {
  margin-bottom: 20px;
}

.quiz-progress {
  margin-bottom: 20px;
}

.video-info {
  margin-bottom: 10px;
}

.progress {
  height: 20px;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.progress-bar {
  line-height: 20px;
  text-align: center;
  color: white;
}

.textClr {
  color: white;
}
</style>
