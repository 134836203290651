// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import LoginCom from '@/components/LoginCom.vue';
import DashboardCom from '@/components/DashboardCom.vue';
import GameCategories from '@/components/GameCategories.vue';
import GamesCom from '@/components/GamesCom.vue';
import ProfileView from '@/components/ProfileView.vue';
import CourseView from '@/components/CourseView.vue';
import BenefitsView from '@/components/BenefitsView.vue';
import Certificate from '@/components/CertificateView.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginCom,
    meta: { requiresAuth: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardCom,
    meta: { requiresAuth: true }
  },
  {
    path: '/certificate',
    name: 'Certificate',
    component: Certificate,
    meta: { requiresAuth: true }
  },
  {
    path: '/benefits',
    name: 'BenefitsView',
    component: BenefitsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: ProfileView,
    meta: { requiresAuth: true }
  },
  {
    path: '/courses',
    name: 'CourseView',
    component: CourseView,
    meta: { requiresAuth: true }
  },
  {
    path: '/gamecategories',
    name: 'Gamecategory',
    component: GameCategories,
    meta: { requiresAuth: true }
  },
  {
    path: '/games',
    name: 'GamesCom',
    component: GamesCom,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/components/UsersCom.vue'),
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHashHistory(''), // This is correct for serving your app under /admin/
  routes
});

// Navigation Guard to check for authentication
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userToken = store.state.user.token; // Use Vuex state instead of localStorage

  if (requiresAuth && !userToken) {
    next('/');
  } else {
    next();
  }
});

export default router;
